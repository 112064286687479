@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'EurostileExtended';
	src: url('./assets/fonts/EurostileExtendedBlack/EurostileExtended-Black.svg#EurostileExtended-Black') format('svg'),
		 url('./assets/fonts/EurostileExtendedBlack/EurostileExtended-Black.ttf') format('truetype'),
		 url('./assets/fonts/EurostileExtendedBlack/EurostileExtended-Black.woff') format('woff');
	font-weight: 700;
	font-style: normal;
  }
  
  @font-face {
	font-family: 'EurostileExtendedBlack';
	src: url('./assets/fonts/EurostileExtendedBlack/EurostileExtendedBlack.eot');
	src: url('./assets/fonts/EurostileExtendedBlack/EurostileExtendedBlack.eot?#iefix') format('embedded-opentype'),
		 url('./assets/fonts/EurostileExtendedBlack/EurostileExtendedBlack.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
  }

  @font-face {
	font-family: 'EurostileExtended';
	src: url('./assets/fonts/EurostileExtended/EurostileExtended-Roman.svg#EurostileExtended-Roman') format('svg'),
		 url('./assets/fonts/EurostileExtended/EurostileExtended-Roman.ttf') format('truetype'),
		 url('./assets/fonts/EurostileExtended/EurostileExtended-Roman.woff') format('woff');
	font-weight: 400;
	font-style: normal;
  }
  
  @font-face {
	font-family: 'EurostileExtended';
	src: url('./assets/fonts/EurostileExtended/EurostileExtended.eot');
	src: url('./assets/fonts/EurostileExtended/EurostileExtended.eot?#iefix') format('embedded-opentype'),
		 url('./assets/fonts/EurostileExtended/EurostileExtended.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
  }

  @font-face {
	font-family: 'Roboto-Regular';
	src: url('./assets/fonts/Roboto/Roboto-Regular.eot');
	src: url('./assets/fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		 url('./assets/fonts/Roboto/Roboto-Regular.svg#Roboto-Regular') format('svg'),
		 url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype'),
		 url('./assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
		 url('./assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }
  
  @import "App.css";
  
